import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Markdown from 'react-markdown/with-html'
import Brands from "../components/base/Brands"
import Testimonial from "../components/base/Testimonial"
import WhyChooseUs from "../components/base/WhyChooseUs"
import CustomFooter from "../components/CustomFooter"
import HeroTheOther from "../components/HeroTheOther"
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SEO from '../components/SEO'
import SubTitle from "../components/SubTitle"


export const HybridSecurityAnalysisPageTemplate = ({
  seo,
  hero,
  contactus,
  scheduleSection,
  securitysection,
  uhamsection,
  sptsection,
  ohasection,
  fixedimagesection
}) => (
    <div className="custompages">
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" blogservice={true} />
      <HeroTheOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <SubTitle textAlign="center" title={contactus.title} />
            </div>
          </div>
          <div className="columns">
            <div className="column  has-text-centered">
              <a href={contactus.buttonlink} >
                <button class="btn-light">{contactus.buttontxt}</button>
              </a>
            </div>
          </div>

        </div>
      </section>

      <section className="section" style={{ background: `${scheduleSection.sectionbg}` }}>
        <div className="container scedulesection">


          <div className="columns" >
            <div className="column is-8 " style={{ margin: "0rem 0" }}>

              {
                scheduleSection.description.list.map((description, i) => {
                  return (
                    <div key={i}>
                      <p style={{ margin: ".5rem 0", color: "#53c5d1", }}>
                        <Markdown
                          source={description.title}
                          renderers={{ paragraph: 'span', link: props => <a style={{ 'text-decoration': "underline", color: "#53c5d1", }} href={props.href} target="_blank" rel="noopener noreferrer" >{props.children}</a> }} />
                      </p>
                    </div>
                  )
                })
              }

              <a href={scheduleSection.buttonlink} >
                <button style={{ marginTop: 25 }} class="btn-light">{scheduleSection.buttontxt}</button>
              </a>
            </div>

            <div className="column mobile-center has-text-centered hero-image">
              <div style={{ maxWidth: 250, height: 90, marginTop: 104 }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/Cypress-Data-Defense-Website-Yellow-Alien-1.png' }} />

              </div>
            </div>
          </div>

        </div>
      </section>


      <section className="section" style={{ background: `${securitysection.sectionbg}` }}>
        <div className="container securitysection">

          <div className="columns">
            <div className="column">
              <SubTitle title={securitysection.title} color={securitysection.textcolor} />
            </div>
          </div>
          <div className="columns" >
            <div className="column " style={{ margin: "0rem 0" , paddingTop: "0rem" }}>

              {
                securitysection.description.list.map((description, i) => {
                  return (
                    <div key={i}>
                      <p style={{ margin: "1rem 0", color: "#ffffff", }}>
                        <Markdown
                          source={description.title}
                          renderers={{ paragraph: 'span', link: props => <a style={{ 'text-decoration': "underline", color: "#ffffff", }} href={props.href} target="_blank" rel="noopener noreferrer" >{props.children}</a> }} />
                      </p>
                    </div>
                  )
                })
              }

              <a href={securitysection.buttonlink} >
                <button class="">{securitysection.buttontxt}</button>
              </a>
            </div>

          </div>

        </div>
      </section>


      <section className="section" style={{ background: `${uhamsection.sectionbg}` }}>
        <div className="container scedulesection">
          <div style={{ margin: "1rem 0" }}>
            <SubTitle title={uhamsection.title} color="#ffffff" margin=".5rem 0" />
            {
              uhamsection.descriptions.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#53c5d1", }}>{description.title}</p>
                  </div>
                )
              })
            }
          </div>
          <div className="columns">
            <div className="column is-8">

              {
                uhamsection.descriptions.list2.map((description, i) => {
                  return (
                    <div key={i}>
                      <p style={{ margin: "1rem 0", color: "#53c5d1", }}>{description.title}</p>
                    </div>
                  )
                })
              }

            </div>
            <div className="column is-4 mobile-center has-text-centered hero-image">

              <div style={{ maxWidth: "100%", height: 90, marginTop: 10 }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/security-hybrid.png' }} />

              </div>

            </div>


          </div>

          <div className="columns">
            <div className="column  ">
              <a href={uhamsection.buttonlink} >
                <button class="btn-light">{uhamsection.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>


      <Brands />
      <WhyChooseUs
        title="Have our security specialists conduct a hybrid security analysis of your mobile applications."
        list={[{
          title: 'We are security experts who train others.',
          para: 'Our application security specialists regularly instruct for large corporations and global training institutions. We teach developers and organizations how to properly combine mobile application penetration testing and source code review.'
        }, {
          title: 'We are all developers and we understand code.',
          para: 'We aren’t only experts in security, we also know how mobile applications are (and SHOULD be) built. We can leverage this knowledge to provide the most thorough security reviews of your mobile apps.We can also help you develop a secure software development lifecycle (SSDLC) for your mobile applications.',
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Have Us Run a Hybrid Analysis for Enhanced Security',
        subtitle: '',
        para: [
          'Our security engineers combine dynamic testing with static testing techniques, resulting in a thorough hybrid mobile application security analysis.'

        ],
        linkTitle: 'Talk to Our Experts Today'
      }} />



    </div>
  )

HybridSecurityAnalysisPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  scheduleSection: PropTypes.object,
  securitysection: PropTypes.object,
  uhamsection: PropTypes.object,
  sptsection: PropTypes.object,
  ohasection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const HybridSecurityAnalysisPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <HybridSecurityAnalysisPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        scheduleSection={frontmatter.scheduleSection}
        securitysection={frontmatter.securitysection}
        uhamsection={frontmatter.uhamsection}
        sptsection={frontmatter.sptsection}
        ohasection={frontmatter.ohasection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

HybridSecurityAnalysisPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HybridSecurityAnalysisPage

export const pageQuery = graphql`
  query HybridSecurityAnalysisPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "hybrid-security-analysis" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        scheduleSection{
          sectionbg
          textcolor
          description{
            list{
              title
            }
          }
          buttontxt
          buttonlink
        }
        securitysection{
          sectionbg
          textcolor
          title
          description{
            list{
              title
            }
          }
          buttontxt
          buttonlink
        }
        uhamsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions{
            list{
              title
            }
            list2{
              title
            }
          }
          buttontxt
          buttonlink
        }
        sptsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        ohasection {
          title
          secondtitle
          subheader
          sidedescriptions
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
